import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import Tab from '../components/Tab'
import { IntroQuery } from '../graphql-types'
import { MDXProvider } from '@mdx-js/react'

const subject = {
  background: 'background',
  aboutUs: 'about-us',
  tutorial: 'tutorial'
}

interface IntroProps {
  data: IntroQuery
}

const DEFAULT_BODY = 'no body'

const isTutorialTab = (mdx: IntroQuery['mdx']): boolean => {
  return mdx?.fields?.subject === subject.tutorial
}

const Intro: React.FC<IntroProps> = (props: IntroProps) => {
  const {
    data: { mdx }
  } = props

  const tabOrder = [subject.background, subject.aboutUs, subject.tutorial]

  return (
    <Tab tabOrder={tabOrder} tabData={props.data} activeTab={mdx?.id ?? ''} subject={mdx?.fields?.subject ?? ''}>
      <MDXProvider
        components={{
          p: props => (isTutorialTab(mdx) ? <div {...props} /> : <p {...props} />) //
        }}
      >
        <MDXRenderer>{mdx?.body ?? DEFAULT_BODY}</MDXRenderer>
      </MDXProvider>
    </Tab>
  )
}

export default Intro

export const query = graphql`
  query Intro($locale: String!, $title: String!) {
    allMdx(filter: { frontmatter: { template: { eq: "intro" } } }) {
      edges {
        node {
          id
          frontmatter {
            title
          }
          fields {
            locale
            isDefault
          }
          parent {
            ... on File {
              relativeDirectory
            }
          }
        }
      }
    }
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      id
      body
      fields {
        locale
        subject
      }
      frontmatter {
        title
      }
    }
  }
`
